import { get, post,del,put } from './http'
import utils from '../utils'

var buff = utils.isProject ? 'pro' : 'dev';
var accessTokenCookieName = utils.cookie.get('access_token_share_video_' + buff);


//获取机会浮层
export const getChangeInfo = (app_key,timestamp,sign,token,user_id) =>get(`${utils.activityApiUrl}/v1/TwelveDay/getExchangeList?app_key=${app_key}&timestamp=${timestamp}&sign=${sign}&token=${token}&user_id=${user_id}`,{},"")
//五一活动详情
export const getActivityInfo = (app_key,timestamp,sign,token,user_id) =>get(`${utils.activityApiUrl}/v1/TwelveDay/getActivityInfo?app_key=${app_key}&timestamp=${timestamp}&sign=${sign}&token=${token}&user_id=${user_id}`,{},"")
//奖品弹层
export const getPriceInfo = (app_key,timestamp,sign,token,user_id) =>get(`${utils.activityApiUrl}/v1/TwelveDay/draw?app_key=${app_key}&timestamp=${timestamp}&sign=${sign}&token=${token}&user_id=${user_id}`,{},"")
//查询已抽奖次数
export const drawCount = (app_key,timestamp,sign,token,user_id) =>get(`${utils.activityApiUrl}/v1/TwelveDay/drawCount?app_key=${app_key}&timestamp=${timestamp}&sign=${sign}&token=${token}&user_id=${user_id}`,{},"")
//我的奖励列表
export const myAwardList = (app_key,timestamp,sign,token,user_id) =>get(`${utils.activityApiUrl}/v1/TwelveDay/drawlist?app_key=${app_key}&timestamp=${timestamp}&sign=${sign}&token=${token}&user_id=${user_id}`,{},"")
//获取我的邀请码接口
export const myInviteCode = (app_key,timestamp,sign,token,user_id) =>get(`${utils.activityApiUrl}/v1/TwelveDay/getInviteCode?app_key=${app_key}&timestamp=${timestamp}&sign=${sign}&token=${token}&user_id=${user_id}`,{},"")
//提交用户信息
// export const commitUserInfo = (app_key,timestamp,sign,token,user_id,type,phone,user_name,region,address) =>post(`/v1/TwelveDay/saveAddress`,{app_key,timestamp,sign,token,user_id,type,phone,user_name,region,address},"")
export const commitUserInfo = (user_id,type,phone,user_name,region,address) =>post(`${utils.activityApiUrl}/v1/TwelveDay/saveAddress`,{user_id,type,phone,user_name,region,address},"")
// export const commitUserInfo = (user_id,type,phone,user_name,region,address) =>post(`/v1/TwelveDay/saveAddress`,{user_id,type,phone,user_name,region,address},"")
//兑换抽奖次数
export const addExangeTime = (app_key,timestamp,sign,token,user_id,type,spend,num) =>post(`${utils.activityApiUrl}/v1/TwelveDay/exchangeNum`,{app_key,timestamp,sign,token,user_id,type,spend,num},"")
//活动点击浏览统计
export const activityStatistics = (app_key,timestamp,sign,token,user_id,type) =>post(`${utils.activityApiUrl}/v1/TwelveDay/activityStatistics`,{app_key,timestamp,sign,token,user_id,type},"")

//微信二次分享
export const wxConfig = (url,access_token)=>get(`${utils.apiUrl}v1/weixin/getShareInfo`,{url,access_token})
//获取access_token
export const getAccessToken = ()=>get(`${utils.apiUrl}v1/weixin/getShareInfo`,{'client_id': 'msd0c28f00413d6c95','secret': 'a89c4996d0c28f00413d6c95ff6e4a2a'})

export default {
    getPriceInfo,
    getChangeInfo,
    getActivityInfo,
    myAwardList,
    myInviteCode,
    commitUserInfo,
    wxConfig,
    getAccessToken,
    addExangeTime,
    activityStatistics,
    drawCount,
}