
const wuyiRule = ()=> import("../pages/rule.vue");
const wuyiMyAward = ()=> import("../pages/myAward.vue");
const wuyiUserInfo = ()=> import("../pages/userInfo.vue");
const wuyiInvite = ()=> import("../pages/invite.vue");
const wuyishare = ()=> import("../pages/sharePage.vue");
const wuyiIndex = ()=> import("../pages/index.vue");
const wuyiInviteFriend = ()=> import("../pages/inviteFriend.vue");


export default [
    
    {path:"/wuyiRule",component:wuyiRule,meta:{title:'五一活动'}},
    {path:"/wuyiMyAward",component:wuyiMyAward,meta:{title:'五一活动'}},
    {path:"/wuyiInvite",component:wuyiInvite,meta:{title:'五一活动'}},
    {path:"/wuyiInviteFriend",component:wuyiInviteFriend,meta:{title:'五一活动'}},
    {
        path:"/wuyiMyAward",
        component:wuyiMyAward,
        children:[{
            path:'wuyiUserInfo',
            component:wuyiUserInfo
        },],
        meta:{title:'收货地址填写'}
    },
    {
        path:"/wuyiIndex",
        component:wuyiIndex,
        meta:{title:'五一活动'}
    },

    {path:"/wuyiShare",component:wuyishare,meta:{title:'五一活动'}},
    {
        path:"*",
        redirect:'/wuyiIndex'
    },
]