<template>
    <div v-if="$route.path === '/wuyiInviteFriend'">
        <div class="inviteHeader">
            <div v-wechat-title="$route.meta.title"></div>
            <img src="../static/image/indeximg/invitation_card@2x.png" class="bj" />
            <div class="inviteHeaderTop">
                <div class="myInviteText">邀请函</div>
                <div class="myCode">{{myCode}}</div>
                <div class="copyBtn"  
                     v-clipboard:copy="myCode"
                     v-clipboard:success="onCopy"
                     v-clipboard:error="onError"
                >复制</div>
            </div>
            <div class="inviteHeaderBottom" :style="{'font-size': '16px','width':'274px'}">你的好友邀请你来云美摄，复制邀请码在云美摄中输入邀请码并完成首次登录</div>
        </div>
        <img src="../static/image/indeximg/invitation_process@2x.png" class="inviteMain" />
        <div class="inviteFooter" @click="downLoad">下载云美摄</div>
    </div>
</template>
<script>
import Vue from "vue";
import { Toast } from "vant";
import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)
export default {
    data() {
        return {
            myCode:'',
            app_key: "3ab58649236688fd",
            secret_key: "0ebadf1ad6c24625a46d3d02d11730f7",
            date: String(new Date().valueOf()).slice(0, 10),
            userId:'',
            token:''
        };
    },
    async mounted(){
        //获取userId、token

        this.userId = this.getRequest().userId ? this.getRequest().userId : "";
        this.token = this.getRequest().token ? this.getRequest().token : "";

        //获取签名
        let sign = this.$md5(this.getSign(this.app_key,this.date,"v1/TwelveDay/getInviteCode",this.secret_key));
        //邀请码
        let {errNo,data,message} = await this.$api.myInviteCode(this.app_key,this.date,sign,this.token,this.userId)
        if(errNo === 0){
            this.myCode = data.invite_code
        }
    },
    methods: {
         // 复制成功
        onCopy(e){
        Toast.success('复制成功')
        },
        // 复制失败
        onError(e){
        Toast.success('复制失败')
        },
        //格式化URL
        getRequest: () => {
            var url = window.location.search; //获取url中"?"符后的字串
            var theRequest = new Object();
            if (url.indexOf("?") != -1) {
                var str = url.substr(1);
                var strs = str.split("&");
                for (var i = 0; i < strs.length; i++) {
                    theRequest[strs[i].split("=")[0]] = decodeURI(
                        strs[i].split("=")[1]
                    );
                }
            }

            return theRequest;
        },
        //获取签名
        getSign: (app_key, date, URL, secret_key) => {
            var sign ="app_key=" +app_key +"&" +"timestamp=" +date +"&" +"uri=" + URL +"&" +"secret_key=" +secret_key;
            return sign;
        },
        //下载云美摄
        downLoad(){
            var ua = window.navigator.userAgent;
            var isIos = !!ua.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
            if (isIos) {
                window.open('http://itunes.apple.com/cn/app/id928359827?mt=8');
            } else if (ua) {
                window.open('http://a.app.qq.com/o/simple.jsp?pkgname=com.cdv.video360&from=singlemessage');
            }
        }

    },
};
</script>
<style scoped>
.inviteHeader {
    width: 375px;
    height: 328px;
    background: #f5574b;
}
.bj {
    width: 323px;
    height: 278px;
    position: absolute;
    top: 20px;
    left: 26px;
}
.inviteHeaderTop {
    position: absolute;
    width: 312px;
    height: 185px;
    text-align: center;
    top: 32px;
    left: 31px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px 0 10px;
}
.myInviteText {
    width: 84px;
    height: 22px;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #757575;
    line-height: 22px;
}
.myCode {
    width: 215px;
    height: 62px;
    font-size: 44px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #333333;
    line-height: 62px;
    letter-spacing: 1px;
}
.copyBtn {
    width: 74px;
    height: 30px;
    background: #ef6248;
    border-radius: 16px;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 30px;
    margin-top: 10px;
}
.inviteHeaderBottom {
    width: 274px;
    height: 44px;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ef6248;
    line-height: 22px;
    position: absolute;
    top: 237px;
    left: 48px;
    text-align: center;
}
.inviteMain {
    display: block;
    width: 345px;
    height: 136px;
    margin: 16px auto 0;
}
.inviteFooter {
    width: 345px;
    height: 46px;
    background: #f5574b;
    border-radius: 4px;
    font-size: 17px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 46px;
    text-align: center;
    margin: 68px auto 0;
}
</style>